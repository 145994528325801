import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: '/page1',
        hidden: true
    },
    {
        path: '/page1',
        name: 'page1',
        component: () => import('../views/PageView1.vue')
    },
    {
        path: '/page2',
        name: 'page2',
        component: () => import('../views/PageView2.vue')
    },
    {
        path: '/page3',
        name: 'page3',
        component: () => import('../views/PageView3.vue')
    },
    {
        path: '/page4',
        name: 'page4',
        component: () => import('../views/PageView4.vue')
    },
    {
        path: '/page5',
        name: 'page5',
        component: () => import('../views/PageView5.vue')
    },
    {
        path: '/page6',
        name: 'page6',
        component: () => import('../views/PageView6.vue')
    },
    {
        path: '/page7',
        name: 'page7',
        component: () => import('../views/PageView7.vue')
    },
    {
        path: '/page8',
        name: 'page8',
        component: () => import('../views/PageView8.vue')
    },
    {
        path: '/page9',
        name: 'page9',
        component: () => import('../views/PageView9.vue')
    },
    {
        path: '/page10',
        name: 'page10',
        component: () => import('../views/PageView10.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router

router.afterEach(() => {
    document.title = '2023年渠道合作指南电子手册';
    window.scrollTo(0, 0);
    // chrome
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0
    // safari
    window.pageYOffset = 0
})
